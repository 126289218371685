// In Elixir, path helper function prevent the need
// to update URLs in multiple places. However, this
// doesn't translate to JavaScript.
//
// This file exists to encapsulate every link used in
// the JavaScript app in order to organize links and
// minimize broken links as much as possible.
//
// If a URL changes in the app, it should only have to
// change here. (And potentially callers will have to
// change what they pass in.)

import { Falsy } from "utility-types";

import type { Id } from "@/react/types";
import { appendGroupIdToUrl } from "@/react/common/utils/append-group-id";

export const home = "/manager/groups";

export const accessCode = {
  createForGuest: ({ unitId, groupId }: { unitId: Id; groupId: Id }) =>
    `/manager/groups/${groupId}/units/${unitId}/access-codes?category=manager`,
  summary: ({ subtype, accessCodeId }: { subtype: string; accessCodeId: Id }) =>
    `/manager/${subtype}/${accessCodeId}`,
};

export const auth = {
  login: ({ status, message }: { status?: string; message?: string } = {}) => {
    if (status && message) {
      return `/login?status=${status}&message=${message}`;
    }
    return "/login";
  },
  updatePassword: ({ token }: { token: string }) => `/forgot-password/${token}`,
  acceptInvite: ({ token }: { token: string }) => `/invite/${token}`,
};

export const users = {
  groups: ({ userId }: { userId: Id }) => `/manager/users/${userId}/groups`,
  createUser: () => appendGroupIdToUrl(`/manager/users/new`),
  list: () => appendGroupIdToUrl("/manager/users"),
  orgEdit: () => "/manager/users/:user_id/edit",
  orgNew: () => "/manager/users/new",
  orgEditUser: ({ userId }: { userId: Id }) =>
    appendGroupIdToUrl(`/manager/users/${userId}/edit`),
  orgUserGroup: ({ userId }: { userId: Id }) =>
    appendGroupIdToUrl(`/manager/users/${userId}/groups`),
};

export const dashboard = {
  assetProtection: () => appendGroupIdToUrl("/manager/dashboard"),
  residents: () => appendGroupIdToUrl("/manager/dashboard/residents"),
  tours: () => appendGroupIdToUrl("/manager/dashboard/tours"),
  toursHub: () => appendGroupIdToUrl("/manager/dashboard/tours/hub"),
  prospectsHub: () =>
    appendGroupIdToUrl("/manager/dashboard/tours/prospects/hub"),
  reportSets: {
    view: () => appendGroupIdToUrl("/manager/dashboard/report-sets/view"),
    create: () => appendGroupIdToUrl("/manager/dashboard/report-sets/new"),
    edit: ({ report_set_id }: { report_set_id: Id }) =>
      appendGroupIdToUrl(
        `/manager/dashboard/report-sets/${report_set_id}/edit`
      ),
    download: ({ report_set_id }: { report_set_id: Id }) =>
      appendGroupIdToUrl(
        `/manager/dashboard/report-sets/${report_set_id}/download`
      ),
  },
};

export const ecomm = {
  shop: ({ slug }: { slug: string }) => `/shop/${slug}`,
};

export const organization = {
  home: ({ groupId }: GroupParams) =>
    appendGroupIdToUrl("/manager/organization", groupId),
  getOrganization: ({ organizationId }: { organizationId: Id }) =>
    `/organizations/${organizationId}`,
  templates: {
    list: () => appendGroupIdToUrl("/manager/organization/templates"),
    create: () => "/manager/organization/templates/new",
    single: ({ name }: { name: string }) =>
      appendGroupIdToUrl(`/manager/organization/templates/${name}`),
  },
  reports: {
    download: ({ report_config_key }: { report_config_key: string }) =>
      `/manager/reports/download/${report_config_key}`,
    exports: () => appendGroupIdToUrl("/manager/dashboard/exports"),
    assets: () => appendGroupIdToUrl("/manager/dashboard/assets"),
    residents: () => appendGroupIdToUrl("/manager/dashboard/residents"),
    tours: () => appendGroupIdToUrl("/manager/dashboard/tours"),
    support: () => "/manager/dashboard/support",
  },
  roles: {
    index: () => appendGroupIdToUrl("/manager/roles"),
    new: () => appendGroupIdToUrl("/manager/roles/new"),
    view: ({ role_id }: { role_id: Id }) =>
      appendGroupIdToUrl(`/manager/roles/${role_id}`),
  },
  myAccess: () => "/manager/my-access",
  settings: () => appendGroupIdToUrl("/manager/organization-settings/profile"),
  imports: (params?: GroupParams) =>
    appendGroupIdToUrl("/manager/organization/imports", params?.groupId),
  shop: {
    settings: () => appendGroupIdToUrl("/manager/organization/shops/settings"),
    products: () => appendGroupIdToUrl("/manager/organization/shops/products"),
  },
  hardwareOrders: {
    list: () => appendGroupIdToUrl("/manager/organization/hardware-orders"),
    settings: () =>
      appendGroupIdToUrl("/manager/organization/hardware-orders/settings"),
    selectDevices: ({ orderId }: { orderId: Id }) =>
      appendGroupIdToUrl(`/manager/organization/hardware-orders/${orderId}`),
  },
  tours: () => appendGroupIdToUrl("/manager/tours"),
  communityDefaults: {
    index: () => "/manager/organization/community-defaults",
    businessHours: () =>
      appendGroupIdToUrl(
        "/manager/organization/community-defaults/business-hours"
      ),
    groupLockConfig: () =>
      appendGroupIdToUrl(
        "/manager/organization/community-defaults/lock-config"
      ),
    groupThermostatConfig: () =>
      appendGroupIdToUrl(
        "/manager/organization/community-defaults/thermostat-config"
      ),
    notifications: {
      index: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-defaults/notifications"
        ),
      edit: ({ employee_role_id }: { employee_role_id: Id }) =>
        appendGroupIdToUrl(
          `/manager/organization/community-defaults/notifications/${employee_role_id}`
        ),
    },
    tours: {
      clickToUnlock: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-defaults/tours/click-to-unlock"
        ),
      schedule: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-defaults/tours/schedule"
        ),
      prospect: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-defaults/tours/prospect"
        ),
      marketingTheme: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-defaults/tours/marketing-theme"
        ),
      indemnityAgreement: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-defaults/tours/indemnity-agreement"
        ),
    },
  },
  communitySettings: {
    bulkUpdateSettings: () =>
      appendGroupIdToUrl("/manager/organization/community-settings"),
    defaultSettings: () =>
      appendGroupIdToUrl("/manager/organization/community-settings/default"),
    history: () =>
      appendGroupIdToUrl("/manager/organization/community-settings/history"),
    viewLog: ({ id }: { id: Id }) =>
      appendGroupIdToUrl(
        `/manager/organization/community-settings/history/${id}`
      ),
    settingsSelection: () =>
      appendGroupIdToUrl("/manager/organization/community-settings/selection"),
    viewSummary: () =>
      appendGroupIdToUrl(
        "/manager/organization/community-settings/view-summary"
      ),
    tours: {
      enableToursToggle: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-settings/enable-tours-toggle"
        ),
      clickToUnlock: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-settings/click-to-unlock"
        ),
      toursSchedule: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-settings/tours-schedule"
        ),
      marketingTheme: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-settings/marketing-theme"
        ),
      prospectSettings: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-settings/prospect-settings"
        ),
      indemnityAgreement: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-settings/indemnity-agreement"
        ),
    },
    devices: {
      lockSettings: () => "/manager/organization/community-settings/lock",
      lock: (type: "general" | "model" | "vacant") =>
        `/manager/organization/community-settings/lock/${type}`,
      thermostatSettings: () =>
        "/manager/organization/community-settings/thermostat",
      thermostat: (type: "general" | "model" | "vacant") =>
        `/manager/organization/community-settings/thermostat/${type}`,
      sensorSettings: () => "/manager/organization/community-settings/sensor",
    },
    residents: () =>
      appendGroupIdToUrl("/manager/organization/community-settings/residents"),
    staffNotifications: {
      notifications: () =>
        appendGroupIdToUrl(
          "/manager/organization/community-settings/notifications"
        ),
      edit: ({ employee_role_id }: { employee_role_id: Id }) =>
        appendGroupIdToUrl(
          `/manager/organization/community-settings/notifications/${employee_role_id}`
        ),
    },
    maintenance: () =>
      appendGroupIdToUrl(
        "/manager/organization/community-settings/maintenance"
      ),
  },
  credentials: {
    list: () => appendGroupIdToUrl("/manager/organization/credentials"),
    create: () => appendGroupIdToUrl("/manager/organization/credentials/new"),
    edit: ({ id }: { id: number }) =>
      appendGroupIdToUrl(`/manager/organization/credentials/${id}`),
  },
  webhooks: {
    list: () => appendGroupIdToUrl("/manager/organization/webhooks"),
  },
};

type GroupParams = { groupId: Id };
type GroupProspectParams = { groupId: Id; prospectId: Id };
type GroupTourParams = { groupId: Id; tourId: Id };
type GroupTourTimeSlotParams = { groupId: Id; timeSlotId: Id };

export const group = {
  list: () => "/manager/groups",
  map: () => "/manager/groups/map",
  create: () => "/manager/groups/new",
  edit: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/edit`,
  show: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/overview`,
  sync: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/sync`,
  access: {
    show: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/access`,
    staffAccess: ({ groupId, userId }: { groupId: Id; userId: Id }) =>
      `/manager/groups/${groupId}/credentials/staff/${userId}`,
    myCode: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/my-code`,
    doors: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/doors`,
  },
  activity: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/activity`,
  guests: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/guests/resident-guest-access-codes`,
  tours: {
    create: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/tours/new`,
    list: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/tours`,
    unit_availability: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/tours/unit-availability`,
    show: ({ groupId, tourId }: GroupTourParams) =>
      `/manager/groups/${groupId}/tours/${tourId}/overview`,
    reservedTimeSlots: {
      list: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/tours/reserved-time-slots`,
      create: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/tours/reserved-time-slots/new`,
      update: ({ groupId, timeSlotId }: GroupTourTimeSlotParams) =>
        `/manager/groups/${groupId}/tours/reserved-time-slots/${timeSlotId}/update`,
    },
  },

  prospects: {
    list: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/prospects`,
    show: ({ groupId, prospectId }: GroupProspectParams) =>
      `/manager/groups/${groupId}/prospects/${prospectId}/overview`,
  },

  maintenance: {
    list: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/maintenance`,
    vendors: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/maintenance/team`,
    create: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/maintenance/new`,
  },
  amenities: {
    list: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/amenities`,
    reservations: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/amenities/reservations`,
  },
  users: {
    show: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/users`,
    create: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/users/new`,
  },
  units: {
    hub: {
      syncCellStats: ({ hubId }: { hubId: Id }) =>
        `/manager/hubs/${hubId}/sync-cell-stats`,
    },
    accessCodeSummary: ({
      unitId,
      groupId,
      qs,
    }: {
      groupId: Id;
      unitId: Id;
      qs?: string;
    }) => `/manager/groups/${groupId}/units/${unitId}/access-codes${qs}`,
    show: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
      `/manager/groups/${groupId}/units/${unitId}/overview`,
    notes: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
      `/manager/groups/${groupId}/units/${unitId}/notes`,
    scenes: {
      list: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
        `/manager/groups/${groupId}/units/${unitId}/scenes`,
      new: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
        `/manager/groups/${groupId}/units/${unitId}/scenes/new`,
      edit: ({
        groupId,
        unitId,
        sceneId,
      }: {
        groupId: Id;
        unitId: Id;
        sceneId: Id;
      }) => `/manager/groups/${groupId}/units/${unitId}/scenes/${sceneId}/edit`,
      info: ({
        groupId,
        unitId,
        sceneId,
      }: {
        groupId: Id;
        unitId: Id;
        sceneId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/scenes/${sceneId}/devices`,
      newDevice: ({
        groupId,
        unitId,
        sceneId,
        deviceId,
      }: {
        groupId: Id;
        unitId: Id;
        sceneId: Id;
        deviceId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/scenes/${sceneId}/device/${deviceId}`,
      editDevice: ({
        groupId,
        unitId,
        sceneId,
        deviceId,
      }: {
        groupId: Id;
        unitId: Id;
        sceneId: Id;
        deviceId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/scenes/${sceneId}/device/${deviceId}/edit`,
      addDevice: ({
        groupId,
        unitId,
        sceneId,
      }: {
        groupId: Id;
        unitId: Id;
        sceneId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/scenes/${sceneId}/add-devices`,
    },
    automations: {
      info: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
        `/manager/groups/${groupId}/units/${unitId}/automations`,
      new: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
        `/manager/groups/${groupId}/units/${unitId}/automations/new`,
      edit: ({
        groupId,
        unitId,
        automationId,
      }: {
        groupId: Id;
        unitId: Id;
        automationId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/automations/${automationId}/edit`,
      summary: ({
        groupId,
        unitId,
        automationId,
      }: {
        groupId: Id;
        unitId: Id;
        automationId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/automations/${automationId}`,
      addTrigger: ({
        groupId,
        unitId,
        automationId,
        deviceId,
      }: {
        groupId: Id;
        unitId: Id;
        automationId: Id;
        deviceId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/automations/${automationId}/device/${deviceId}/add`,
      editTrigger: ({
        groupId,
        unitId,
        automationId,
        deviceId,
        deviceAttributeCauseId,
      }: {
        groupId: Id;
        unitId: Id;
        automationId: Id;
        deviceId: Id;
        deviceAttributeCauseId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/automations/${automationId}/device/${deviceId}/device-attribute-cause/${deviceAttributeCauseId}/edit`,
      addOutcome: ({
        groupId,
        unitId,
        automationId,
        outcomeType,
      }: {
        groupId: Id;
        unitId: Id;
        automationId: Id;
        outcomeType: string;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/automations/${automationId}/add-outcome/type/${outcomeType}`,
      editOutcome: ({
        groupId,
        unitId,
        automationId,
        outcomeId,
        outcomeType,
      }: {
        groupId: Id;
        unitId: Id;
        automationId: Id;
        outcomeId: Id;
        outcomeType: string;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/automations/${automationId}/edit-outcome/${outcomeId}/type/${outcomeType}`,
      addDeviceOutcome: ({
        groupId,
        unitId,
        automationId,
        deviceId,
      }: {
        groupId: Id;
        unitId: Id;
        automationId: Id;
        deviceId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/automations/${automationId}/add-device-outcome/${deviceId}`,
      editDeviceOutcome: ({
        groupId,
        unitId,
        automationId,
        deviceId,
      }: {
        groupId: Id;
        unitId: Id;
        automationId: Id;
        deviceId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/automations/${automationId}/edit-device-outcome/${deviceId}`,
    },
    maintenance: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
      `/manager/groups/${groupId}/units/${unitId}/maintenance`,
    list: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/units`,
    edit: ({ unitId, groupId }: { groupId: Id; unitId: Id }) =>
      `/manager/groups/${groupId}/units/${unitId}/edit`,
    new: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/units/new`,
    activity: ({ groupId, unitId }: { unitId: Id; groupId: Id }) =>
      `/manager/groups/${groupId}/units/${unitId}/activity`,
    leases: {
      index: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
        `/manager/groups/${groupId}/units/${unitId}/leases`,
      new: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
        `/manager/groups/${groupId}/units/${unitId}/leases/new`,
      edit: ({
        groupId,
        unitId,
        leaseId,
      }: {
        groupId: Id;
        unitId: Id;
        leaseId: Id;
      }) => `/manager/groups/${groupId}/units/${unitId}/leases/${leaseId}/edit`,
    },
    residents: {
      new: ({
        groupId,
        unitId,
        leaseId,
      }: {
        groupId: Id;
        unitId: Id;
        leaseId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/leases/${leaseId}/new-resident`,
      single: ({
        groupId,
        unitId,
        residentId,
        returnTo = null,
      }: {
        groupId: Id;
        unitId: Id;
        residentId: Id;
        returnTo?: string | Falsy;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/residents/${residentId}${
          returnTo ? `?return_to=${returnTo}` : ""
        }`,
    },
    devices: {
      status: ({
        groupId,
        unitId,
        deviceId,
      }: {
        groupId: Id;
        unitId: Id;
        deviceId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/devices/${deviceId}/status`,
      schedule: ({
        groupId,
        unitId,
        deviceId,
      }: {
        groupId: Id;
        unitId: Id;
        deviceId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/devices/${deviceId}/schedule`,
      newSchedule: ({
        groupId,
        unitId,
        deviceId,
      }: {
        groupId: Id;
        unitId: Id;
        deviceId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/devices/${deviceId}/schedule/new`,
      editSchedule: ({
        groupId,
        unitId,
        deviceId,
        automationId,
      }: {
        groupId: Id;
        unitId: Id;
        deviceId: Id;
        automationId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/devices/${deviceId}/schedule/${automationId}/edit`,
      edit: ({
        groupId,
        unitId,
        deviceId,
      }: {
        groupId: Id;
        unitId: Id;
        deviceId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/devices/${deviceId}/edit`,
      configuration: ({
        groupId,
        unitId,
        deviceId,
      }: {
        groupId: Id;
        unitId: Id;
        deviceId: Id;
      }) =>
        `/manager/groups/${groupId}/units/${unitId}/devices/${deviceId}/configuration`,
    },
    rooms: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
      `/manager/groups/${groupId}/units/${unitId}/rooms`,
  },
  edit_user: ({ groupId, userId }: { groupId: Id; userId: Id }) =>
    `/manager/groups/${groupId}/users/${userId}/edit`,
  leasing: ({
    groupId,
    moveIns,
    moveOuts,
    withExceptions,
  }: {
    groupId: Id;
    moveIns?: boolean;
    moveOuts?: boolean;
    withExceptions?: boolean;
  }) => {
    const path = `/manager/groups/${groupId}/leasing`;
    if (moveIns) {
      return `${path}?move_ins=true`;
    }
    if (moveOuts) {
      return `${path}?move_outs=true`;
    }
    if (withExceptions) {
      return `${path}?with_exceptions=true`;
    }
    return path;
  },
  dataAudit: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/data`,
  settings: {
    show: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/settings`,
    tour: {
      settings: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/settings/tour`,
      general: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/settings/tour/general`,
      amenities: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/settings/tour/amenities`,
      schedule: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/settings/tour/schedule`,
      prospectSettings: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/settings/tour/prospect-information`,
      communityMaps: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/settings/tour/community-maps`,
      communityAccess: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/settings/tour/community-access`,
      marketingTheme: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/settings/tour/marketing-theme`,
      nearbyProperties: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/settings/tour/nearby-properties`,
      setup: ({ groupId }: GroupParams) =>
        `/manager/groups/${groupId}/settings/tour/setup`,
    },
  },
  communityWifi: {
    settings: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/community-wifi`,
    jobs: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/community-wifi/jobs`,
  },
};

export const maintenance = {
  index: ({ groupId, qs }: { groupId: Id; qs?: string | Falsy }) =>
    `/manager/groups/${groupId}/maintenance${qs ? `?${qs}` : ""}`,
  unitIndex: ({
    groupId,
    unitId,
    qs,
  }: {
    groupId: Id;
    unitId: Id;
    qs?: string | Falsy;
  }) =>
    `/manager/groups/${groupId}/units/${unitId}/maintenance${
      qs ? `?${qs}` : ""
    }`,
  workOrder: {
    new: ({
      groupId,
      ids,
      teamMemberId,
      marketingName,
    }: {
      groupId: Id;
      ids?: Id[];
      teamMemberId?: Id | Falsy;
      marketingName?: string;
    }) => {
      const qs = [];
      if (ids) {
        qs.push(`ids=${ids.join(",")}`);
      }
      if (teamMemberId) {
        qs.push(`maintenance_team_member_id=${teamMemberId}`);
      }
      if (marketingName) {
        qs.push(`marketing_name=${marketingName}`);
      }
      const query = qs.length ? `?${qs.join("&")}` : "";
      return `/manager/groups/${groupId}/maintenance/new${query}`;
    },
    extend: ({ groupId, workOrderId }: { groupId: Id; workOrderId: Id }) =>
      `/manager/groups/${groupId}/maintenance/${workOrderId}/extend`,
  },
  teamMember: {
    create: ({ groupId, woId }: { groupId: Id; woId: Id }) =>
      `/manager/groups/${groupId}/maintenance/team/new?wo_id=${woId}`,
    show: ({ groupId, teamMemberId }: { groupId: Id; teamMemberId: Id }) =>
      `/manager/groups/${groupId}/maintenance/team/${teamMemberId}/work-orders`,
    index: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/maintenance/team`,
    new: ({ groupId }: { groupId: Id }) =>
      `/manager/groups/${groupId}/maintenance/team/new`,
    edit: ({ groupId, teamMemberId }: { groupId: Id; teamMemberId: Id }) =>
      `/manager/groups/${groupId}/maintenance/team/${teamMemberId}/edit`,
    credentials: ({
      groupId,
      teamMemberId,
    }: {
      groupId: Id;
      teamMemberId: Id;
    }) =>
      `/manager/groups/${groupId}/maintenance/team/${teamMemberId}/credentials`,
    createCredentials: ({
      groupId,
      teamMemberId,
    }: {
      groupId: Id;
      teamMemberId: Id;
    }) =>
      `/manager/groups/${groupId}/credentials/maintenance/${teamMemberId}/new`,
  },
  overview: ({ groupId, maintenanceId }: { groupId: Id; maintenanceId: Id }) =>
    `/manager/groups/${groupId}/maintenance/${maintenanceId}/overview`,
};

export const profile = {
  accountProfile: () => `/manager/account/profile`,
  accountChangePassword: () => `/manager/account/change-password`,
  accountSecurity: () => `/manager/account/security`,
};

export const temporaryAccessCode = {
  regenerateAccessCode: ({ token }: { token: string }) =>
    `/p/access-codes/${token}/regenerate`,
};

export const unit_install = {
  addNewDevice: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
    `/manager/groups/${groupId}/units/${unitId}/installer/device-setup/add-new-zwave-device`,
  installedDevices: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
    `/manager/groups/${groupId}/units/${unitId}/installer/device-setup/installed-devices`,
  pendingSmartStartDevices: ({
    groupId,
    unitId,
  }: {
    groupId: Id;
    unitId: Id;
  }) =>
    `/manager/groups/${groupId}/units/${unitId}/installer/device-setup/pending-smart-start`,
  backupAccessCodes: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
    `/manager/groups/${groupId}/units/${unitId}/installer/backup-access-codes`,
};

export const nonZwave = {
  edit: ({
    groupId,
    unitId,
    deviceId,
  }: {
    groupId: Id;
    unitId: Id;
    deviceId: Id;
  }) =>
    `/manager/groups/${groupId}/units/${unitId}/non-zwave-devices/${deviceId}/edit`,
  igloo: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/access-control/devices`,
  iglooGroup: ({ groupId, deviceId }: GroupParams & { deviceId: Id }) =>
    `/manager/groups/${groupId}/access-control/devices/${deviceId}`,
};

export const parking = {
  parkingMap: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/parking/map`,
  spacesList: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/parking/spaces`,
  viewSpace: ({ groupId, spaceId }: { groupId: Id; spaceId: Id }) =>
    `/manager/groups/${groupId}/parking/spaces/${spaceId}`,
  sectionList: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/parking/sections`,
  sectionUserList: ({ groupId, sectionId }: { groupId: Id; sectionId: Id }) =>
    `/manager/groups/${groupId}/parking/sections/${sectionId}/reservations`,
  sectionSpaceList: ({ groupId, sectionId }: { groupId: Id; sectionId: Id }) =>
    `/manager/groups/${groupId}/parking/sections/${sectionId}/spaces`,
  viewSectionSpace: ({
    groupId,
    sectionId,
    spaceId,
  }: {
    groupId: Id;
    sectionId: Id;
    spaceId: Id;
  }) =>
    `/manager/groups/${groupId}/parking/sections/${sectionId}/spaces/${spaceId}`,
  rateList: ({ groupId }: { groupId: Id }) =>
    `/manager/groups/${groupId}/parking/rates`,
};

// TODO: This should move into organizations, since it's within that scope.
export const region = {
  index: () => appendGroupIdToUrl("/manager/regions"),
  new: () => "/manager/regions/new",
  edit: ({ regionId }: { regionId: Id }) => `/manager/regions/${regionId}/edit`,
};

export const resident = {
  index: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
    `/manager/groups/${groupId}/units/${unitId}/leases`,
  welcome: () => `/resident/welcome`,
  residentCard: ({
    groupId,
    unitId,
    residentId,
  }: {
    groupId: Id;
    unitId: Id;
    residentId: Id;
  }) => `/manager/groups/${groupId}/units/${unitId}/residents/${residentId}`,

  devices: () => "/resident",
  scenes: () => "/resident/scenes",
  access: () => "/resident/access",
  ring: () => "/resident/ring",
  wifi: () => "/resident/wifi",
};

export const unit = {
  show: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
    `/manager/groups/${groupId}/units/${unitId}/overview`,
  maintenance: {
    create: ({ groupId, unitId }: { groupId: Id; unitId: Id }) =>
      `/manager/groups/${groupId}/units/${unitId}/maintenance/new`,
    overview: ({
      groupId,
      unitId,
      maintenanceId,
    }: {
      groupId: Id;
      unitId: Id;
      maintenanceId: Id;
    }) =>
      `/manager/groups/${groupId}/units/${unitId}/maintenance/${maintenanceId}/overview`,
  },
  thirdPartyDevices: ({
    groupId,
    unitId,
    deviceId,
  }: {
    groupId: Id;
    unitId: Id;
    deviceId: Id;
  }) =>
    `/manager/groups/${groupId}/units/${unitId}/access-control-devices/${deviceId}/edit`,
};

export const support = {
  smartRentStaff: {
    organizations: (filters?: Record<string, any>) => {
      const qs = [];
      if (filters?.id) {
        qs.push(`id=${filters.id}`);
      }

      const query = qs.length ? `?${qs.join("&")}` : "";
      return `/support/organizations${query}`;
    },
  },
};

export const settings = {
  amenities: {
    create: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/tour/amenities/create`,
  },
  base: ({ groupId }: GroupParams) => `/manager/groups/${groupId}/settings`,
  general: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/settings/general`,
  businessHours: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/settings/business-hours`,
  tour: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/settings/tour`,
  maintenance: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/settings/maintenance`,
  communityWifi: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/settings/community-wifi`,
  customBranding: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/settings/custom-branding`,
  notifications: {
    base: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/notifications`,
    type: ({ groupId, type }: { groupId: Id; type: string }) =>
      `/manager/groups/${groupId}/settings/notifications/${type}`,
  },
  staff: {
    base: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/staff`,
  },
  vacantStaff: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/settings/vacant-manager-access`,
  parking: {
    base: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/parking`,
    guestParking: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/guest-parking`,
  },
  residents: {
    base: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/residents`,
    guestAccess: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/residents/guest-access`,
    appSettings: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/residents/app-settings`,
  },
  integrations: {
    base: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/integrations`,
    overview: ({
      groupId,
      providerSlug,
    }: {
      groupId: Id;
      providerSlug: string;
    }) =>
      `/manager/groups/${groupId}/settings/integrations/${providerSlug}/overview`,
    integration: ({
      groupId,
      providerSlug,
    }: {
      groupId: Id;
      providerSlug: string;
    }) =>
      `/manager/groups/${groupId}/settings/integrations/${providerSlug}/configuration`,
    configuration: ({
      groupId,
      providerSlug,
    }: {
      groupId: Id;
      providerSlug: string;
    }) =>
      `/manager/groups/${groupId}/settings/integrations/${providerSlug}/configuration`,
    integrationSettings: ({
      groupId,
      providerSlug,
    }: {
      groupId: Id;
      providerSlug: Id;
    }) =>
      `/manager/groups/${groupId}/settings/integrations/${providerSlug}/settings`,
    credentials: ({
      groupId,
      providerSlug,
    }: {
      groupId: Id;
      providerSlug: string;
    }) =>
      `/manager/groups/${groupId}/settings/integrations/${providerSlug}/credentials`,
    syncHistory: ({
      groupId,
      providerSlug,
    }: {
      groupId: Id;
      providerSlug: string;
    }) =>
      `/manager/groups/${groupId}/settings/integrations/${providerSlug}/history`,
    deviceMapping: ({
      groupId,
      providerSlug,
    }: {
      groupId: Id;
      providerSlug: string;
    }) =>
      `/manager/groups/${groupId}/settings/integrations/${providerSlug}/devices`,
    migration: ({
      groupId,
      providerSlug,
    }: {
      groupId: Id;
      providerSlug: string;
    }) =>
      `/manager/groups/${groupId}/settings/integrations/${providerSlug}/migration`,
  },
  propertyConstructionMode: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/settings/construction-mode`,
  propertyConstructionModeLocks: ({ groupId }: GroupParams) =>
    `/manager/groups/${groupId}/settings/construction-mode/locks`,
  propertyDeviceConfig: {
    index: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/devices`,
    lock: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/lock-config`,
    lockStatusList: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/lock-config/status`,
    lockStatus: ({ groupId, jobId }: { groupId: Id; jobId: Id }) =>
      `/manager/groups/${groupId}/settings/lock-config/status/${jobId}`,
    lockVacant: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/lock-config/vacant`,
    lockModel: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/lock-config/model`,
    thermostat: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/thermostat-config`,
    thermostatVacant: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/thermostat-config/vacant`,
    thermostatModel: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/thermostat-config/model`,
    thermostatOccupied: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/thermostat-config/occupied`,
    thermostatStatusList: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/thermostat-config/status`,
    thermostatStatus: ({ groupId, jobId }: GroupParams & { jobId: Id }) =>
      `/manager/groups/${groupId}/settings/thermostat-config/status/${jobId}`,
    sensor: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/sensor-config`,
  },
  accessGroups: {
    unitGroups: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/access-groups/unit-group-management`,
    parkingGroups: ({ groupId }: GroupParams) =>
      `/manager/groups/${groupId}/settings/access-groups/parking-access-groups`,
  },
};

export const shop = {
  public: {
    product: ({
      orgSlug,
      productId,
    }: {
      orgSlug: string;
      productId: string | number;
    }) => `/shop/${orgSlug}/${productId}`,
    productList: ({ orgSlug }: { orgSlug: string }) => `/shop/${orgSlug}`,
    cart: ({ orgSlug }: { orgSlug: string }) => `/shop/${orgSlug}/cart`,
    review: ({ orgSlug }: { orgSlug: string }) => `/shop/${orgSlug}/review`,
  },
};

type ScopeOfWorkParams = { scopeOfWorkId: Id };

export const scopeOfWork = {
  list: () => "/manager/scope-of-work",
  show: ({ scopeOfWorkId }: ScopeOfWorkParams) =>
    `/manager/scope-of-work/${scopeOfWorkId}`,
};

export const unauthenticated = {
  terms: () => `/p/terms-of-service/`,
};

export const dev = {
  scaffoldGroup: () => "/dev/scaffold/group",
  scaffoldTour: () => "/dev/scaffold/tour",
  scaffoldUnit: () => "/dev/scaffold/unit",
};
